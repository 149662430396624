import
{
  SELECT_DESARROLLO,
  DESARROLLO_SUCCESS
} from '../actions';

export const desarrollo = ( project, history ) => (
{
  type: SELECT_DESARROLLO,
  payload: { project, history }
});

export const desarrolloSuccess = ( project ) => (
{
  type: DESARROLLO_SUCCESS,
  payload: { project }
});
