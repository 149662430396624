
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

//import of API
import { api } from '../../helpers/Api';

import
{
  SELECT_DESARROLLO
} from '../actions';

import
{
  desarrolloSuccess
} from './actions';

export function* watchPutDesarrollo()
{
    yield takeEvery(SELECT_DESARROLLO, putDesarrollo);
}

function* putDesarrollo({ payload })
{

  const { desarrollo } = payload.project;
  const { history } = payload;
  localStorage.setItem( 'desarrollo', desarrollo);

  if ( desarrollo === 'admin' )
  {
    yield put(desarrolloSuccess( desarrollo ));
    history.push('/admin');
  }
  else
  {
    yield put(desarrolloSuccess( desarrollo ));
    history.push('/app');
  }

}



export default function* rootSaga()
{
  yield all(
  [
    fork(watchPutDesarrollo),
  ]);
}
