import
{
  SELECT_DESARROLLO,
  DESARROLLO_SUCCESS
} from '../actions';

const INIT_STATE =
{
    desarrollo: localStorage.getItem('desarrollo')
};

export default (state = INIT_STATE, action) =>
{
  switch (action.type)
  {
    case SELECT_DESARROLLO:
        return { ...state };
    case DESARROLLO_SUCCESS:
        return { ...state, desarrollo: action.payload.project };
    default: return { ...state };
  }
}
