//libreria Axios para peticiones htpp
import axios from 'axios';

//importar la configuracion basica de la API
import { apiLocal } from '../constants/defaultValues';

const api = axios.create(
{
  baseURL: apiLocal,
});

export
{
  api
};
