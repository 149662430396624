import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

//api
import { api } from './helpers/Api';

const store = configureStore();

// Interceptor que se ejecuta antes de enviar la petición
api.interceptors.request.use(function (config)
{
  //obtenemos los headers de la petición
  const { headers } = config;

  //obtenemos el auth del state
  const { authUser } = store.getState();

  //si tenemos el token, lo incluimos en cada petición que se realice
  if (authUser.token)
  {
    //agregamos el campo de Authorization
    headers.Authorization = 'Bearer ' + authUser.token;
  }

  return config;
}, function (error)
{
  return Promise.reject(error);
});

//render de la aplicación NO TOCAR!!
const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App' ));

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
